export const socialsData = {
  github: "https://github.com/chuksdozie",
  facebook: "",
  linkedIn: "https://www.linkedin.com/in/chiedozie-chukwu-9ab85513a/",
  tiktok: "https://www.tiktok.com/@iamchuksdozie?lang=en",
  instagram: "",
  codepen: "",
  twitter: "",
  reddit: "",
  blogger: "",
  medium: "",
  stackOverflow: "",
  gitlab: "",
  youtube: "",
};
