export const educationData = [
  {
    id: 1,
    institution: "Federal University of Technology, Owerri. Nigeria",
    course: "B.Eng Materials And Metallurgical Engineering",
    startYear: "2013",
    endYear: "2018",
  },
  // {
  //     id: 2,
  //     institution: 'Delhi University',
  //     course: 'M.A. History',
  //     startYear: '2019',
  //     endYear: '2021'
  // },
  // {
  //     id: 3,
  //     institution: 'Masai School of Coding',
  //     course: 'Fullstack Web-Development',
  //     startYear: '2021',
  //     endYear: 'Present'
  // },
];
